import React from 'react';
import InputMask from "react-input-mask";
import { TextField } from '@mui/material';

const DateInputMask = ({ label, name, value, onChangeHandler, onBlueHandler, isDisable, ...rest }) => (
  <InputMask
    mask="99-99-9999"
    value={value || ""}
    disabled={isDisable || false}
    maskChar={null}
    onChange={(event) => onChangeHandler(name, event.target.value)}
    onBlur={(event) => onBlueHandler(name, event.target.value)}
  >
    {() => (
      <TextField
        {...rest}
        label={label || "Date"}
        size="small"
        fullWidth
        placeholder="DD-MM-YYYY"
        variant="filled"
        disabled={isDisable || false}
        name={name || `date`}
        style={{ background: "#ffffff" }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )}
  </InputMask>
);

export default DateInputMask;
