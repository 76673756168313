import React from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';

const AutocompleteChips = ({ label, name, allValues, setAllValue }) => {

  const handleAddChip = (event, newChips) => {
    const uniqueChips = Array.from(new Set(newChips));
    setAllValue({ ...allValues, [name]: uniqueChips });
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      value={allValues[name]}
      onChange={handleAddChip}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            label={option}
            {...getTagProps({ index })}
            sx={{ padding: '2px' }}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={label || "Enter values"}
          placeholder="Type and press Enter"
        />
      )}
      sx={{
        width: '100%',
      }}
    />
  );
};

export default AutocompleteChips;
