import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TextField, Button, Autocomplete } from "@mui/material";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import regex from "../../utils/regex";
import {
  createEntity,
  saveValue,
  userDetailsSelector,
} from "../../store/slices/applicationFormSlice";
import { entityTypeList, ERRORS_MESSAGE, fieldEntityRequiredMessage } from "../../constants";
import AutocompleteChips from "../customComponents/AutocompleteChips";
import DateInputMask from "../customComponents/DateInputMask";

const ManualEntity = React.memo(({ applicationId }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar()
  const { savingEntityDetails } = useSelector(userDetailsSelector);

  const [fieldErrors, setFieldErrors] = useState({
    abn: "",
    abnStatus: "",
    abnStatusEffectiveFrom: "",
    acn: "",
    businessName: "",
    entityName: "",
    entityTypeName: "",
    gst: "",
  })

  const [allValues, setAllValue] = useState({
    abn: "",
    abnStatus: "",
    abnStatusEffectiveFrom: "",
    acn: "",
    businessName: [],
    entityName: "",
    entityTypeName: "",
    gst: "",
  });

  const dateOptionalValidation = Yup.string()
    .nullable() // Allows null or undefined
    .notRequired() // Makes the field optional
    .matches(/^\d{2}-\d{2}-\d{4}$/, {
      message: "Date must be in the format dd-mm-yyyy",
      excludeEmptyString: true, // Skip regex validation for empty strings
    })
    .test("valid-date", "Invalid date", (value) => {
      if (!value) return true; // Skip validation if value is empty
      const [day, month, year] = value.split("-").map(Number);
      const date = new Date(year, month - 1, day);

      // Validate the logical correctness of the date
      return (
        date.getDate() === day &&
        date.getMonth() === month - 1 &&
        date.getFullYear() === year
      );
    })
    .test("max-date", "Date cannot be in the future", (value) => {
      if (!value) return true; // Skip validation if value is empty
      const [day, month, year] = value.split("-").map(Number);
      const date = new Date(year, month - 1, day);

      // Ensure the date is not in the future
      return date <= new Date();
    });



  const validationSchema = Yup.object({
    abn: Yup.string()
      .nullable()
      .notRequired()
      .matches(regex.ABNOptional, "Invalid ABN.")
      .max(11, "Maximum of 11 characters"),
    acn: Yup.string()
      .required(fieldEntityRequiredMessage.acn)
      .matches(regex.ACNRegex, "Invalid ACN.")
      .max(9, "Maximum of 9 characters"),
    abnStatus: Yup.string()
      .nullable()
      .max(20, "Maximum of 20 characters")
      .when("someOtherfield", {
        is: true, // or a condition
        then: Yup.string().required(fieldEntityRequiredMessage.abnStatus),
      }),
    abnStatusEffectiveFrom: dateOptionalValidation,
    entityName: Yup.string()
      .required(fieldEntityRequiredMessage.entityName)
      .min(2, "Minimum of 2 characters")
      .max(50, "Maximum of 50 characters"),
    entityTypeName: Yup.string()
      .nullable()
      .oneOf(entityTypeList, "Please select valid entity type")
      .required(fieldEntityRequiredMessage.entityType),
    gst: dateOptionalValidation,
    businessName: Yup.string().optional(),
  });

  const validateField = async (fieldName, value) => {
    const name = fieldName.split("_")[0];
    try {
      await validationSchema.validateAt(name, { [name]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handle = {
    onChangeField: async (fieldName, value) => {
      let isValid = true;

      if (!isValid) {
        await validateField(fieldName, value);
        return;
      }

      setAllValue({ ...allValues, [fieldName]: value });
      await validateField(fieldName, value);
    },
    checkEntityCompleteness: (entity) => {
      for (let key in entity) {
        const optionalFields = ["abn", "abnStatus", "abnStatusEffectiveFrom", "gst", "businessName"];
        if (!optionalFields.includes(key) && entity.hasOwnProperty(key)) {
          if (!entity[key]) {
            return false;
          }
        }
      }
      return true;
    },
    saveEntity: () => {
      if (!handle.checkEntityCompleteness(allValues)) return

      const {
        abn,
        abnStatus,
        abnStatusEffectiveFrom,
        acn,
        businessName,
        entityName,
        entityTypeName,
        gst,
      } = allValues

      // Make payload similar like google entity search
      const dataToBeSave = {
        Abn: abn || "",
        AbnStatus: abnStatus || "",
        AbnStatusEffectiveFrom: abnStatusEffectiveFrom || null,
        Acn: acn,
        BusinessName: businessName || [],
        EntityName: entityName,
        EntityTypeName: entityTypeName,
        Gst: gst || null,
        AddressState: "",
      }

      dispatch(createEntity({ abrData: dataToBeSave, applicationId })).then((res) => {
        dispatch(saveValue({ isManualEntity: { show: false } }));

        if (!res?.payload?.data?.data) {
          enqueueSnackbar(res?.payload?.data?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    }
  };

  const isEntityCompleted = handle.checkEntityCompleteness(allValues)

  return (
    <Grid>
      <Grid container spacing={1}>
        <Grid container md={12} sm={12} xl={12} item spacing={1}>
          <Grid item md={3} sm={6} xs={6}>
            <TextField
              fullWidth
              id="id-abn"
              type="text"
              name='abn'
              label='Abn'
              variant="filled"
              size="small"
              value={allValues?.abn}
              error={fieldErrors?.abn}
              helperText={fieldErrors?.abn}
              onChange={(event) => handle.onChangeField('abn', event?.target?.value)}
            />
          </Grid>

          <Grid item md={3} sm={6} xs={6}>
            <TextField
              fullWidth
              id="id-acn"
              type="text"
              name='acn'
              label='Acn*'
              variant="filled"
              size="small"
              value={allValues?.acn}
              error={fieldErrors?.acn}
              helperText={fieldErrors?.acn}
              onChange={(event) => handle.onChangeField('acn', event?.target?.value)}

            />
          </Grid>

          <Grid item md={3} sm={6} xs={6}>
            <TextField
              fullWidth
              id="id-abnStatus"
              type="text"
              name='abnStatus'
              label='Abn Status'
              variant="filled"
              size="small"
              value={allValues?.abnStatus}
              error={fieldErrors?.abnStatus}
              helperText={fieldErrors?.abnStatus}
              onChange={(event) => handle.onChangeField('abnStatus', event?.target?.value)}
            />
          </Grid>

          <Grid item md={3} sm={6} xs={6}>
            <DateInputMask
              fullWidth
              id="id-abnStatusEffectiveFrom"
              type="text"
              name="abnStatusEffectiveFrom" // Date selector
              label="Abn Status Effective From"
              variant="filled"
              size="small"
              value={allValues?.abnStatusEffectiveFrom}
              isDisable={false}
              onChangeHandler={(name, value) => handle.onChangeField(name, value)}
              onBlueHandler={() => { }}
              error={fieldErrors?.abnStatusEffectiveFrom}
              helperText={fieldErrors?.abnStatusEffectiveFrom}
            />
          </Grid>

        </Grid>

        <Grid container md={12} sm={12} xl={12} item spacing={1}>
          <Grid item md={4} sm={6} xs={6}>
            <TextField
              fullWidth
              id="id-entityName"
              type="text"
              name='entityName'
              label='Entity Name*'
              variant="filled"
              size="small"
              value={allValues?.entityName}
              error={fieldErrors?.entityName}
              helperText={fieldErrors?.entityName}
              onChange={(event) => handle.onChangeField('entityName', event?.target?.value)}
            />
          </Grid>

          <Grid item md={5} sm={6} xs={6}>
            <Autocomplete
              value={allValues?.entityTypeName}
              options={entityTypeList}
              name='entityTypeName'
              onChange={(event, newValue) => handle.onChangeField('entityTypeName', newValue)}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  id="id-entityTypeName"
                  type="text"
                  name='entityTypeName'
                  label='Entity type*'
                  variant="filled"
                  size="small"
                  error={fieldErrors?.entityTypeName}
                  helperText={fieldErrors?.entityTypeName}
                />
              )}
            />
          </Grid>

          <Grid item md={3} sm={6} xs={6}>
            <DateInputMask
              fullWidth
              id="id-gst"
              type="text"
              name="gst" // Date selector
              label="GST"
              variant="filled"
              size="small"
              value={allValues?.gst}
              isDisable={false}
              onChangeHandler={(name, value) => handle.onChangeField(name, value)}
              onBlueHandler={() => { }}
              error={fieldErrors?.gst}
              helperText={fieldErrors?.gst}
            />
          </Grid>
        </Grid>

        <Grid container md={12} sm={12} xl={12} item spacing={1}>
          <Grid item md={8} sm={6} xs={6}>
            <AutocompleteChips
              label="Business Names"
              name="businessName"
              allValues={allValues}
              setAllValue={setAllValue}
            />
          </Grid>
        </Grid>

        <Grid container md={12} sm={12} xl={12} item spacing={1}>
          <Grid item md={3} sm={3}>
            <Button
              variant="outlined"
              size="small"
              disabled={!isEntityCompleted || savingEntityDetails}
              onClick={handle.saveEntity}
              style={{ marginTop: "10px" }}
            >
              {savingEntityDetails ? 'Saving...' : 'Save Entity'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default ManualEntity;