import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Close from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { organisationSelector, inviteUsers, getAllOrganisation } from "../../store/slices/organisationSlice";
import { userSelector } from "../../store/slices/userSlice";
import regex from "../../utils/regex";
import { saveValue } from "../../store/slices/applicationFormSlice";
import { validateEmail } from "../../store/slices/usersSlice";
import { ERRORS_MESSAGE, SUCCESS_MESSAGE } from "../../constants";


export default function InviteUsers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = useParams();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [userType, setUserType] = useState("");
  const [organisation, setOrganisation] = useState({
    name: "",
    orgId: ""
  });
  const [invites, setInvites] = useState([]);
  const [canAdd, setCanAdd] = useState(false);
  const token = localStorage.getItem("token");

  const userTypeOptions = ["admin", "user", "support"];

  const userState = useSelector(userSelector);
  const { organisationList } = useSelector(organisationSelector); // inviteUsersSuccess

  const sortedOrgList = organisationList?.docs?.map((org) => ({ name: org?.name, orgId: org?._id }));
  const organisationNameList = sortedOrgList && sortedOrgList.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const validateEmailAddress = async (em) => {
    if (regex.email.test(em.toLowerCase())) {
      setEmailError(false);
      dispatch(saveValue({ emailInUse: false }));

      setEmailErrorText("");
    } else {
      setEmailError(true);
      setEmailErrorText("Not a valid email address.");
    }

    if (em !== "") {
      await dispatch(validateEmail(email)).unwrap();
    } else {
      setEmailError(true);
      setEmailErrorText("Email is required!");
    }

    if (em && em.length) {
      const emailFound = invites?.find((invite) => invite?.email?.toLowerCase() === em.toLowerCase());
      if (emailFound) {
        setEmailError(true);
        setEmailErrorText("Email already added to List");
        return;
      }
    } else {
    }
  };

  useEffect(() => {
    if (userState?.emailInUse) {
      setEmailError(true);
      return setEmailErrorText("Email address already in use.");
    }

    setEmailError(false);
    return setEmailErrorText("");
  }, [userState?.emailInUse]);

  function handleAddEmail() {
    const uniqueId = uuidv4();
    const findOrg = organisationNameList?.find((org) => organisation?.name === org.name);
    setInvites([...invites, { email, userType, organisation: findOrg, uuid: uniqueId }]);
    setEmail("");
    setUserType("");
    setOrganisation("");
  }

  function handleInviteUsers() {
    const newInvites = invites.map((invite) => ({ ...invite, organisation: invite?.organisation?.orgId }));
    dispatch(inviteUsers({
      userId: userId,
      invites: newInvites,
    })).then((res) => {
      if (res?.payload?.data?.data) {
        setTimeout(() => {
          navigate("/users")
        }, 3000);

        enqueueSnackbar(res?.payload?.data?.status_message || SUCCESS_MESSAGE.linkMsg, {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    });
  }

  function handleRemove(uniqueId) {
    const inviteList = [...invites];
    const index = inviteList.findIndex((e) => e.uuid === uniqueId);
    inviteList.splice(index, 1);
    setInvites(inviteList);
  }

  // useEffect(() => {
  //   if (inviteUsersSuccess) {
  //     localStorage.setItem("tokenExpired", true);
  //     localStorage.removeItem("token");
  //     navigate("/login");
  //   }
  // }, [inviteUsersSuccess]);

  useEffect(() => {
    if (email && userType && !emailError) {
      setCanAdd(true);
    } else {
      setCanAdd(false);
    }
  }, [email, emailError, userType]);

  useEffect(() => {
    dispatch(
      getAllOrganisation({
        cursor: "1",
      }),
    );
  }, []);

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        // style={{ padding: "30px 50px 100px" }}
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          height="100vh"
          // justifyContent="center"
          alignItems="center"
          direction="column"
          textAlign="center"
          style={{ padding: "10vh 0", marginTop: "10vh" }}
        >
          <Typography variant="h4" fontWeight="800" style={{ letterSpacing: "-1.8px" }}>
            Invite colleagues to your Business/Organisation
          </Typography>
          {/* <br />
        <br /> */}
          {/* <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}>
          This is it.
        </Typography> */}
          <br />
          {/* <br /> */}
          <Typography variant="h6" fontWeight="400" style={{ margin: "0 0 20px 0", fontSize: "16px" }}>
            Invite by adding their work email addresses below.
          </Typography>
          <Box
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              marginBottom: "30px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "20px",
              }}
            >
              <TextField
                size="small"
                label="Email"
                value={email}
                sx={{ bgcolor: "background.paper" }}
                helperText={emailErrorText}
                error={emailError}
                onChange={(event) => setEmail(event.target.value)}
                onBlur={(event) => validateEmailAddress(event.target.value)}
                style={{ marginRight: "10px" }}
              />

              <FormControl sx={{ minWidth: 120, marginRight: "10px" }} size="small">
                <InputLabel id="demo-select-small-label">User Type</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={userType}
                  label="User Type"
                  onChange={(event) => setUserType(event.target.value)}
                >
                  {userTypeOptions.map((item) => (
                    <MenuItem value={item}>{item.toUpperCase()}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              {organisationNameList && organisationNameList.length > 0 && (
                <FormControl sx={{ minWidth: 240, marginRight: "10px" }} size="small">
                  <Autocomplete
                    name="organisation"
                    size="small"
                    value={organisation || ""}
                    options={organisationNameList}
                    onChange={(event, newValue) => setOrganisation(newValue)}
                    getOptionLabel={(option) => option?.name || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Organisations"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              )}

              <Button variant="contained" color="secondary" onClick={handleAddEmail} disabled={!canAdd}>
                Add email
              </Button>
            </Box>
            {/* <Typography variant="h6">Invitees list</Typography> */}
            {invites && (
              <List dense={true} sx={{ width: "100%" }}>
                {invites.map((invite) => (
                  <ListItem
                    sx={{ bgcolor: "background.paper" }}
                    style={{ marginBottom: "5px", padding: "10px" }}
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" size="large" onClick={() => handleRemove(invite.uuid)}>
                        <Close />
                      </IconButton>
                    }
                  >
                    <Typography style={{ width: "calc(50%)" }}>{invite.email}</Typography>
                    <Typography style={{ width: "calc(50% - 42px)", textAlign: "center" }}>
                      {invite.userType.toUpperCase()}
                    </Typography>
                    <Typography style={{ width: "calc(50% - -91px)" }}>
                      {invite.organisation?.name?.toUpperCase()}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          <Button
            // fullWidth
            disabled={invites?.length <= 0}
            color="secondary"
            variant="contained"
            onClick={handleInviteUsers}
          >
            Send invites and continue
          </Button>
          <Button
            // fullWidth
            color="secondary"
            variant="link"
            onClick={() => (!token ? navigate("/applications") : navigate("/users"))}
          >
            {!token ? "Skip" : "Cancel"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
